import React from "react";

const HeadScript = ({ url }) => {
  return (
    <code>
      <pre>
        <span className="text-bold">
          &lt;script async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"&gt;
        </span>
        <span className="text-bold">&lt;/script&gt;</span>
        <br />
        <span className="text-bold">
          &lt;script async
          src="https://ads.digitalcaramel.com/prebid.js"&gt;&lt;/script&gt;
        </span>
        <br />
        <div>
          <span className="text-bold">
            const dcJs = document.createElement('script');{" "}
          </span>
          <br />
          <span className="text-bold">
            dcJs.src = "https://ads.digitalcaramel.com/js/prebid/{url}
            .js?ts="+new Date().getTime();
          </span>
          <br />
          <span className="text-bold">dcJs.async = true;</span>
          <br />
          <span className="text-bold">document.head.appendChild(dcJs);</span>
          <br />
        </div>
      </pre>
    </code>
  );
};
export default HeadScript;
